import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';

function App() {
  return (
      <Router>
    <div className="App">
      <header className="App-header">
        <img src="./img/logo.svg" className="App-logo" alt="logo" />
        <p>Hier entsteht die Webseite der Dermatologischen Kosmetikerin Kerstin Münzer-Woolley</p>
          <p>Bei Fragen kontaktieren Sie mich bitte unter Telefon: <a href="tel:01722336240">01722336240</a> oder Mail: <a
              href="mainto:info@kosmetik-kmw.de">info@kosmetik-kmw.de</a></p>
       <p> <a href="/impressum">Impressum</a></p>
      </header>
    </div>
      </Router>
  );
}

export default App;
